/* eslint-disable react-hooks/exhaustive-deps */
import useAxios from "axios-hooks";
import Error from "components/error";
import SearchIcon from "components/icons/SearchIcon";
import Loading from "components/loading";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { IoCheckmark } from "react-icons/io5";
import ProjectMilestoneForm from "views/admin/expert/components/ProjectMilestone";

function MilestoneForm({ project, milestonesData = [], fetchMilestones }) {
  const [milestones, setMilestones] = useState(milestonesData);
  const [search, setSearch] = useState("");
  const errorRef = useRef(null);
  const [{ loading: mileLoad, error: mileError }, postMile] = useAxios(
    {
      url: "/main_app/milestones/",
      method: "POST",
    },
    { manual: true }
  );
  const [{ loading: mileDelLoad, error: mileDelError }, delMile] = useAxios(
    {
      url: `/main_app/milestones/`,
      method: "DELETE",
    },
    { manual: true }
  );

  const [{ loading: activityLoad, error: activityError }, postActivity] =
    useAxios(
      {
        url: "/main_app/activities/",
        method: "POST",
      },
      { manual: true }
    );

  const handleMileSave = async (data, update) => {
    console.log("update", update);
    if (!project) return;
    let success = false;

    const mileStone = {
      milestone_name: data.milestoneName,
      milestone_description: data.milestoneDescription,
      milestone_budget: data.milestoneBudget,
      delivery_date: data.milestoneStartDate,
      status: data.status,
      project_id: project.id,
    };
    try {
      await postMile({
        url: update
          ? `/main_app/milestones/${update}/`
          : "/main_app/milestones/",
        method: update ? "PUT" : "POST",
        data: mileStone,
      }).then(async (res) => {
        toast(`Milestone ${update ? "updated" : "created"} successfully`, {
          icon: <IoCheckmark />,
          style: { color: "green" },
        });
        await fetchMilestones();
        success = true;
      });
    } catch (error) {
      console.log(error);
      success = false;
    }

    return success;
  };

  const handleMileDelete = async (milestoneId) => {
    if (!milestoneId) return;
    let success = false;
    try {
      await delMile({
        url: `/main_app/milestones/${milestoneId}/`,
      }).then(async (res) => {
        setMilestones(
          milestones.filter((milestone) => milestone.id !== milestoneId)
        );
        toast("Milestone deleted successfully", {
          icon: <IoCheckmark />,
          style: { color: "green" },
        });
        fetchMilestones().then(() => {
          success = true;
        });
      });
    } catch (error) {
      console.log(error);
      success = false;
    }

    return success;
  };

  const handleActivitySave = async (data, milestoneId, activityId) => {
    if (!milestoneId) return;
    let success = false;
    const activity = {
      activity_name: data.activityName,
      activity_description: data.activityDesc,
      activity_status: data.activityStatus,
      start_date: data.activityStart,
      end_date: data.activityEnd,
      activity_budget: data.activityBudget,
      project_milestone: milestoneId,
      status_memo: data.statusMemo,
      departments: data.activityDepartment,
      output: data.activityOutput,
    };
    try {
      await postActivity({
        url: activityId
          ? `/main_app/activities/${activityId}/`
          : "/main_app/activities/",
        method: activityId ? "PUT" : "POST",
        data: activity,
      }).then(async (res) => {
        toast("Activity created successfully", {
          icon: <IoCheckmark />,
          style: { color: "green" },
          duration: 3000,
        });
        await fetchMilestones();
        success = true;
      });
    } catch (error) {
      console.log(error);
      success = false;
      errorRef.current.scrollIntoView({ behavior: "smooth" });
    }

    return success;
  };

  const handleSearch = () => {
    if (search === "") {
      setMilestones(milestonesData);
      return;
    }
    const filtered = milestonesData.filter((milestone) =>
      milestone?.milestone_name.toLowerCase().includes(search.toLowerCase())
    );
    setMilestones(filtered);
  };

  useEffect(() => {
    if (
      milestonesData &&
      milestonesData.length > 0 &&
      milestones.length === 0
    ) {
      setMilestones(milestonesData);
    }
  }, [milestonesData]);

  useEffect(() => {
    handleSearch();
  }, [search]);

  return (
    <div className="flex gap-2">
      <div className="mt-5 flex min-h-[65vh] w-full flex-grow flex-col justify-between rounded-lg bg-[#F2F9FFCC] py-3 px-4 dark:bg-navy-700">
        <div>
          <div className="mt-2 flex flex-col items-end justify-end gap-2 md:flex-row md:items-center md:gap-0">
            <div className="flex items-center rounded-l-md bg-white dark:bg-navy-900">
              <SearchIcon />
              <input
                type="text"
                placeholder="Search Milestone"
                className=" w-full py-1.5 px-4 focus:outline-none dark:bg-navy-900 dark:text-white dark:placeholder-gray-400"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <button
              className="rounded bg-[#485FE5] px-4 py-1.5 text-white md:rounded-none md:rounded-r-md"
              onClick={() => {
                setMilestones(milestones.concat(1));
              }}
            >
              Add Milestone
            </button>
          </div>
          {(mileError || activityError || mileDelError) && (
            <Error
              ref={errorRef}
              error={mileError || activityError || mileDelError}
            />
          )}
          {/* Milestones */}
          {(mileLoad || activityLoad || mileDelLoad) && <Loading />}
          {(!mileDelLoad || mileLoad || activityLoad) &&
            milestones.map((milestone, index) => (
              <ProjectMilestoneForm
                key={index}
                index={index}
                data={milestone}
                projectName={project?.project_name}
                handleMilestoneSave={handleMileSave}
                handleActivitySave={handleActivitySave}
                loading={mileLoad || activityLoad || mileDelLoad}
                fetchMilestones={fetchMilestones}
                removeMilestone={async (id) => {
                  if (id) {
                    handleMileDelete(id).then((res) => {});
                  }
                  setMilestones(
                    milestones.filter((milestone, i) => i !== index)
                  );
                }}
              />
            ))}
        </div>

        {/* <div className="mt-8 flex items-end justify-end gap-2 pb-2">
          <button className="mt-5 rounded-md bg-[#485FE5] px-5 py-2 text-sm text-white">
            Submit Project plan
          </button>
        </div> */}
      </div>
    </div>
  );
}
export default MilestoneForm;
