import useAxios from "axios-hooks";
import StatusShow from "components/StatusShow";
import { FiX } from "react-icons/fi";

function ActivityViewModal({ id, data, setOpen }) {
  const [{ data: departments }] = useAxios({
    url: "/account/departments/",
    method: "GET",
    params: {
      no_pagination: true,
    },
  });

  const getFoundDepartments = () => {
    let foundDepartments = [];
    if (departments) {
      data?.departments.map((department) => {
        departments.map((dep) => {
          if (dep.id === department) {
            foundDepartments.push(dep);
          }
          return department;
        });
        return department;
      });
    }
    return foundDepartments;
  };

  return (
    <div
      id={id || "ActivityViewModal"}
      tabIndex="-1"
      className="fixed inset-0 z-40 flex w-full items-center justify-center overflow-y-scroll bg-gray-900/50 dark:bg-gray-900/80"
    >
      <div className="relative rounded-lg bg-white pb-6 dark:bg-navy-800 md:min-w-[400px]">
        <button
          tabIndex="-1"
          className="absolute top-4 right-5"
          onClick={() => {
            setOpen(false);
          }}
        >
          <FiX className="mx-auto h-6 w-6 text-red-600" />
        </button>

        <div className="mt-12 flex flex-col items-center px-6 text-center lg:px-12">
          <h1
            className={`max-w-[600px] text-start font-semibold text-gray-900 ${
              data?.activity_name.length > 20 ? "text-2xl" : "text-3xl"
            }`}
          >
            {data?.activity_name || "Activity Name"}
          </h1>
          <div className="mt-8 mb-2 flex max-w-[500px] flex-wrap items-center gap-2 self-start">
            <p className="text-start text-lg font-bold">Description:</p>
            <p className="text-start text-gray-700">
              {data?.activity_description || "N/A"}
            </p>
          </div>
          <div className="mt-5 mb-2 flex max-w-[500px] flex-wrap items-center gap-2 self-start">
            <p className="text-start text-lg font-bold">Memo:</p>
            <p className="text-start text-gray-700">
              {data?.status_memo || "N/A"}
            </p>
          </div>
          <div className="mt-5 mb-2 flex max-w-[500px] flex-wrap items-center gap-2 self-start">
            <p className="text-start text-lg font-bold">Deliverable/Output:</p>
            <p className="text-start text-gray-700">{data?.output || "N/A"}</p>
          </div>
          <div className="mt-5 flex items-start gap-3 self-start">
            <h1 className="-mt-1 text-start text-lg font-bold">Departments:</h1>
            <div className="flex max-w-[500px] flex-wrap gap-3">
              {getFoundDepartments()?.map((dep) => (
                <div
                  key={dep.id}
                  className="flex items-center gap-2 rounded-lg border bg-gray-100 px-2 "
                >
                  <p>{dep.department_name}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-5 mb-2 flex max-w-[500px] flex-wrap items-center gap-2 self-start">
            <p className="text-start text-lg font-bold">Estimated Budget:</p>
            <p className="text-start text-gray-700">
              {Number(data?.activity_budget).toLocaleString() || "N/A"}
            </p>
          </div>
          <div className="mt-3 mb-2 flex max-w-[500px] items-center justify-between gap-5 self-start">
            <p className="text-start text-lg font-bold">Start Date:</p>
            <p className="text-start text-gray-700">{data?.start_date}</p>
          </div>
          <div className="mt-3 mb-2 flex max-w-[500px] items-center justify-between gap-5 self-start">
            <p className="text-start text-lg font-bold">End Date:</p>
            <p className="text-start text-gray-700">{data?.end_date}</p>
          </div>
          {data?.status === "completed" && (
            <>
              <div className="mt-5 mb-2 w-full border-t border-gray-300"></div>
              <div className="mt-5 mb-2 flex max-w-[500px] flex-wrap items-center gap-2 self-start">
                <p className="text-start text-lg font-bold">Actual Budget:</p>
                <p className="text-start text-gray-700">
                  {Number(data?.utilized_budget).toLocaleString() || "N/A"}
                </p>
              </div>
              <div className="mt-3 mb-2 flex max-w-[500px] items-center justify-between gap-5 self-start">
                <p className="text-start text-lg font-bold">
                  Actual Start Date:
                </p>
                <p className="text-start text-gray-700">
                  {data?.real_start_date}
                </p>
              </div>
              <div className="mt-3 mb-2 flex max-w-[500px] items-center justify-between gap-5 self-start">
                <p className="text-start text-lg font-bold">Actual End Date:</p>
                <p className="text-start text-gray-700">
                  {data?.real_end_date}
                </p>
              </div>
              <div className="mt-3 mb-2 flex max-w-[500px] items-center justify-between gap-5 self-start">
                <p className="text-start text-lg font-bold">File:</p>
                <a
                  className="text-start text-blue-700 hover:underline "
                  href={data?.completion_file}
                >
                  {data?.completion_file.split("/").pop().split(".")[0]}
                </a>
              </div>
            </>
          )}
          <div className="mt-3 mb-2 flex max-w-[500px] items-center justify-between gap-5 self-start">
            <p className="text-start text-lg font-bold">Status:</p>
            <StatusShow status={data?.status} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ActivityViewModal;
