import useAxios from "axios-hooks";
import AddSquare from "components/icons/AddSquare";
import { IoArrowForward } from "react-icons/io5";
import { Link } from "react-router-dom";

function SidebarProjects({ setShowModal, link }) {
  const path = window.location.pathname;
  const [{ data: projects }] = useAxios(
    {
      url: "/main_app/projects/",
      params: { page: 1, page_size: 3 },
      method: "GET",
    },
    { useCache: false }
  );
  return (
    <div className="mt-10 px-10">
      <hr className="my-4" />
      <div className="mr-5 ml-2 flex items-center justify-between">
        <h1 className="my-4 ml-2 font-poppins text-sm font-semibold uppercase text-[#787486]">
          My Projects
        </h1>
        {link === "ceo" && (
          <Link to={`/${link}/projects/create`}>
            <AddSquare />
          </Link>
        )}
      </div>

      {projects && (
        <div>
          <ul>
            {projects &&
              [...projects?.results]?.splice(0, 3)?.map((item, i) => {
                const active = path.includes(
                  `/${link}/projects/${item.id}/dashboard`
                );
                return (
                  <Link
                    key={i}
                    to={`/${link}/projects/${item.id}/dashboard`}
                    className={`my-2.5 flex min-w-[200px] items-center gap-2 rounded-lg py-2 px-4 font-poppins hover:underline ${
                      active ? "bg-brand-50 dark:bg-brand-200" : ""
                    }`}
                  >
                    <div
                      className="h-2 w-2 rounded-full"
                      style={{
                        backgroundColor: i % 2 === 0 ? "red" : "blue",
                      }}
                    />
                    <p
                      className={`max-w-[220px] truncate ${
                        active ? "text-brand-500" : "text-gray-700"
                      }`}
                    >
                      {item.project_short_name
                        ? item.project_short_name
                        : item.project_name.length > 10
                        ? item.project_name.substring(0, 10) + "..."
                        : item.project_name}
                    </p>
                  </Link>
                );
              })}
            <li
              className="my-1 flex min-w-[200px] cursor-pointer items-center gap-2 rounded-lg py-2 px-4 font-poppins hover:underline"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <IoArrowForward className="text-gray-700" />
              <p className="text-gray-700">View all projects</p>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
export default SidebarProjects;
