/* eslint-disable react-hooks/exhaustive-deps */
import useAxios from "axios-hooks";
import Error from "components/error";
import BackIcon from "components/icons/BackIcon";
import Loading from "components/loading";
import SidebarModal from "components/popover/SidebarModal";
import { ExpertRouteNameContext } from "layouts/expert";
import {
  createContext,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import { IoChevronBack, IoChevronForward, IoDocument } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import ProjectReport from "views/admin/ceo/ProjectReport";
import MilestoneForm from "views/admin/expert/components/MilestoneForm";
import ProjectMilestoneDetail from "views/admin/expert/components/ProjectMilestoneDetail";
import ProjectStatus from "views/admin/expert/components/ProjectStatus";

export const MilestoneContext = createContext(null);

function ProjectDetails() {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const params = useParams();
  const { setCurrentRoute, currentRoute } = useContext(ExpertRouteNameContext);
  const [isOpen, setIsOpen] = useState(false);

  const [{ data: project, loading, error }, fetchProject] = useAxios(
    {
      url: `/main_app/projects/${params.id}/`,
      method: "GET",
    },
    { manual: true }
  );
  const [{ data: milestonesData, error: milestoneError }, fetchMilestones] =
    useAxios(
      {
        url: `/main_app/projects/${params.id}/nested-milestones/`,
        params: { no_pagination: true },
        method: "GET",
      },
      { manual: true }
    );

  useEffect(() => {
    if (params.id) {
      fetchProject();
      fetchMilestones();
    }
  }, [params.id]);

  useEffect(() => {
    if (project) {
      setCurrentRoute(`${project.project_name} Details`);
    }
    return () => {};
  }, [project, currentRoute]);

  return (
    <MilestoneContext.Provider value={{ fetchMilestones }}>
      <ProjectReport
        project={{ ...project, milestones: { results: milestonesData } }}
        showModal={isOpen}
        setShowModal={setIsOpen}
      />
      <div className=" mt-5 fill-blueSecondary font-poppins md:ml-3">
        <button
          className="fixed top-[50%] -right-0 rounded-full bg-[#485FE5] p-2 dark:bg-navy-700"
          onClick={() => {
            setShowModal(true);
          }}
        >
          <IoChevronForward
            className={`h-7 w-7 rotate-180 text-white md:h-10 md:w-10`}
          />
        </button>

        <SidebarModal
          isOpen={showModal}
          setIsOpen={setShowModal}
          content={<ProjectStatus project={project} setIsOpen={setIsOpen} />}
          closeBtn={
            <button
              className={`fixed top-8 left-[85%] z-50 rounded-full p-3 dark:bg-navy-700 md:left-[96%] md:top-6 3xl:top-[48%] 3xl:left-[66%] 3xl:bg-[#485FE5]`}
              onClick={() => {
                setShowModal(false);
              }}
            >
              <IoChevronBack
                className={`h-7 w-7 rotate-180 text-gray-800 3xl:h-10 3xl:w-10 3xl:text-white`}
              />
            </button>
          }
          bg={"bg-[#F5FAFF]"}
        />

        <div className="flex flex-col gap-8 md:flex-row">
          <BackIcon
            onClick={() => {
              navigate(-1, { replace: true });
            }}
            className="h-4 w-8 cursor-pointer"
          />
          <div className="mt-4 flex  gap-3">
            <div className="h-4 w-4 flex-grow bg-orange-400" />
            <div>
              <p className="text-3xl font-semibold text-gray-700 dark:text-white ">
                Project Planning and Execution
              </p>
              <p className="mt-2 space-x-6 text-sm font-semibold tracking-widest text-gray-700 dark:text-white">
                Project ID:
                <span className="font-normal tracking-widest text-gray-500">
                  {" "}
                  #{project?.id}
                </span>
              </p>
            </div>
          </div>
        </div>
        {error && <Error error={error} />}
        {loading && <Loading />}
        {!loading && project?.execution_plan_status !== "pending" ? (
          <div className="gap-2 3xl:flex">
            <div className="mt-5 flex-grow rounded-lg bg-[#F2F9FFCC] py-3 px-4 dark:bg-navy-700">
              <h1 className="ml-2 font-semibold text-navy-500">Description</h1>
              <p className="mt-2 ml-2 w-full rounded-lg bg-white p-3 text-sm text-[#787486] dark:bg-navy-900 dark:text-white ">
                {project?.project_description}
              </p>

              {/* Milestones */}
              {milestoneError && <Error error={milestoneError} />}

              <Suspense fallback={<Loading />}>
                {milestonesData?.map((milestone) => (
                  <ProjectMilestoneDetail
                    key={milestone.id}
                    data={{
                      ...milestone,
                      project_status: project?.status,
                    }}
                  />
                ))}
              </Suspense>
            </div>
            <div className="w-full 3xl:w-1/4">
              <div className="mt-5 h-fit  rounded-lg bg-[#F2F9FFCC] py-5 px-6 font-poppins dark:bg-navy-700">
                <p className="text-lg font-semibold text-gray-700 dark:text-white ">
                  Files
                </p>
                {project?.files?.map((file) => (
                  <div key={file.id} className="mt-8 flex items-start gap-5 ">
                    <IoDocument color="#2F80ED" size={45} />
                    <p className="text-sm dark:text-white">
                      {file.file.split("/").pop().split(".")[0]}
                    </p>
                    <a
                      href={file.file}
                      target="_blank"
                      rel="noreferrer"
                      className="cursor-pointer text-sm text-blueSecondary underline"
                    >
                      open
                    </a>
                  </div>
                ))}
              </div>
              {project?.execution_plan_status === "rejected" && (
                <div className="mt-5 h-fit  rounded-lg bg-[#F2F9FFCC] py-5 px-6 font-poppins dark:bg-navy-700">
                  <h1 className="text-xl font-semibold text-[#661B17] dark:text-red-400 ">
                    Rejection reason
                  </h1>
                  <p className="mt-3 rounded-lg border border-red-400 bg-red-100 p-5 font-[400]">
                    {project?.feedback}
                  </p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <MilestoneForm
            project={project}
            milestonesData={milestonesData}
            fetchMilestones={fetchMilestones}
          />
        )}
      </div>
    </MilestoneContext.Provider>
  );
}

export default ProjectDetails;
